import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LanguageCode} from './shared/model/language-code.model';
import {Router} from '@angular/router';
import {ROUTES_URL} from './app-routing.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'hs-worker-form';

  get ROUTES_URL() {
    return ROUTES_URL;
  }

  constructor(private translate: TranslateService,
              public router: Router) {
    translate.setDefaultLang(LanguageCode.EN);
    this.useLanguage(LanguageCode.EN);
  }

  ngOnInit(): void {
  }

  getLanguage() {
    return this.translate.currentLang;
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }
}
