import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {WorkerLiteService} from '../../../worker-lite/service/worker-lite.service';
import {WorkerLite} from '../../../worker-lite/model/worker-lite.model';
import {WorkerAccountService} from '../../../worker-account/shared/service/worker-account.service';
import {User} from '../../../worker-account/shared/model/user.model';

@Injectable({
  providedIn: 'root'
})
export class FacadeService {

  private _workerLiteService: WorkerLiteService;
  private get workerLiteService(): WorkerLiteService {
    if (!this._workerLiteService) {
      this._workerLiteService = this.injector.get(WorkerLiteService);
    }
    return this._workerLiteService;
  }

  private _workerAccountService: WorkerAccountService;
  private get workerAccountService(): WorkerAccountService {
    if (!this._workerAccountService) {
      this._workerAccountService = this.injector.get(WorkerAccountService);
    }
    return this._workerAccountService;
  }

  constructor(private injector: Injector) {
  }

  saveWorkerLite(workerLite: WorkerLite): Observable<any> {
    return this.workerLiteService.save(workerLite);
  }

  getBicByIdentificationNumber(identificationNumber: string): Observable<any> {
    return this.workerLiteService.getBicByIdentificationNumber(identificationNumber);
  }

  listCountries(): Observable<any> {
    return this.workerLiteService.listCountries();
  }

  getUserByUuid(uuid: string): Observable<any> {
    return this.workerAccountService.getUserByUuid(uuid);
  }

  createUserAccount(uuid: string, user: User): Observable<any> {
    return this.workerAccountService.createUserAccount(uuid, user);
  }
}
