import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {Gender, WorkerLite} from './model/worker-lite.model';
import {FacadeService} from '../shared/service/facade/facade.service';
import {HttpErrorResponse} from '@angular/common/http';
import {LanguageCode} from '../shared/model/language-code.model';
import {Bic} from '../shared/model/bic.model';
import {ibanValidator} from 'ngx-iban';
import {emailValidator} from '../shared/validator/email.validator';
import {Country} from '../shared/model/country/country.model';
import {MinimumDateValidators} from '../shared/validator/minimum-date.validator';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-worker-lite',
  templateUrl: './worker-lite.component.html',
  styleUrls: ['./worker-lite.component.css']
})
export class WorkerLiteComponent implements OnInit {

  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;

  registrationFrom: UntypedFormGroup;
  workerLite: WorkerLite = new WorkerLite();
  languageCodes = Object.keys(LanguageCode);
  genders = Object.keys(Gender);
  countries: Country[] = [];

  constructor(private formBuilder: UntypedFormBuilder,
              private snackBar: MatSnackBar,
              private facadeService: FacadeService) {
  }

  ngOnInit(): void {
    this.listCountries();
    this.buildRegistrationFrom();
  }

  private listCountries() {
    this.facadeService.listCountries().subscribe(
      countries => {
        Object.assign(this.countries, countries);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );
  }

  private buildRegistrationFrom() {
    this.registrationFrom = this.formBuilder.group({
      firstName: [this.workerLite.firstName, Validators.required],
      lastName: [this.workerLite.lastName, Validators.required],
      address: this.formBuilder.group({
        street: [this.workerLite.address.street, Validators.required],
        number: [this.workerLite.address.number, Validators.required],
        box: [this.workerLite.address.box],
        zip: [this.workerLite.address.zip, Validators.required],
        city: [this.workerLite.address.city, Validators.required],
        country: [this.workerLite.address.country, Validators.required]
      }),
      bankData: this.formBuilder.group({
        bic: [this.workerLite.bankData.bic, Validators.required],
        iban: [this.workerLite.bankData.iban, [Validators.required, ibanValidator()]],
      }),
      workerStatus: this.formBuilder.group({
        nationality: [this.workerLite.workerStatus.nationality, Validators.required],
      }),
      gender: [this.workerLite.gender, Validators.required],
      mobile: [this.workerLite.mobile, Validators.required],
      email: [this.workerLite.email, [Validators.required, emailValidator]],
      dateOfBirth: [this.workerLite.dateOfBirth, [MinimumDateValidators.dateMinimum('1900-01-01')]],
      placeOfBirth: [this.workerLite.placeOfBirth],
      languageCode: [this.workerLite.languageCode, Validators.required]
    });

    this.registrationFrom.controls.bankData.get('iban').valueChanges.subscribe(value => {
      const iban = this.registrationFrom.controls.bankData.get('iban');
      if (iban.valid && iban.value.replace(/\s/g, '').substring(0, 2) === 'BE') {
        this.facadeService.getBicByIdentificationNumber(iban.value.replace(/\s/g, '').substring(4, 7)).subscribe(
          (bic: Bic) => {
            this.registrationFrom.controls.bankData.get('bic').setValue(bic.bicCode);
          }
        );
      }
    });
  }

  onSubmit() {
    if (this.registrationFrom.valid) {
      this.facadeService.saveWorkerLite(this.registrationFrom.value).subscribe({
        next: () => {
          this.openSnackBar('Worker saved');
          return window.location.reload();
        },
        error: (error: HttpErrorResponse) => {
          this.openSnackBar('Error happened during save, please contact a service member : ' + error.message, 'customSnackError');
        }
      });
    } else {
      this.registrationFrom.markAllAsTouched();
    }
  }

  private openSnackBar(message: string, pC: string = 'customSnackValid') {
    this.snackBar.open(message, 'X', {
      duration: 10000,
      panelClass: [pC]
    });
  }

  // Get App on Playstore/App store //
  getAppOnPlaystore() {
    if (/Android/i.test(navigator.userAgent)) {
      window.location.href = "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=net.comexis.hs.workerapp"
    }

    else {
      window.location.href = "https://play.google.com/store/apps/details?id=net.comexis.hs.workerapp" 
    }
  }

  getAppOnAppstore() {
    window.location.href = "https://apps.apple.com/be/app/my-human-supports/id1515453358?l=fr" 
  }


}
