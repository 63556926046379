import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {WorkerUUID} from '../model/worker-uuid.model';
import {environment} from '../../../../environments/environment';
import {User} from '../model/user.model';
import {WorkerLite} from '../../../worker-lite/model/worker-lite.model';

@Injectable({
  providedIn: 'root'
})
export class WorkerAccountService {
  private readonly serviceURL: string = environment.user_service + 'auth';

  constructor(private http: HttpClient) {
  }

  getUserByUuid(uuid: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('uuid', String(uuid));

    return this.http.get<WorkerUUID[]>(this.serviceURL + '/load-by-uuid', {params});
  }

  createUserAccount(uuid: string, user: User): Observable<any> {
    let params = new HttpParams();
    params = params.append('uuid', String(uuid));

    const body = {
      username: user.username,
      newPassword: user.password
    };

    return this.http.post<User>(this.serviceURL + '/update-password', body, {params});
  }
}
