import {Serializer} from 'src/app/shared/model/serializer';
import {BankData, WorkerLite, WorkerStatus} from './worker-lite.model';
import {AddressSerializer} from '../../shared/model/address/address.serializer';
import {Address} from '../../shared/model/address/address.model';
import {DateSerializer} from '../../shared/model/date.serializer';

export class WorkerLiteSerializer implements Serializer {
  private dateSerializer: DateSerializer = new DateSerializer();
  private addressSerializer: AddressSerializer = new AddressSerializer();

  private static bankDataToJson(bankData: BankData) {
    return {
      bic: bankData.bic,
      iban: bankData.iban,
    };
  }

  private static workerStatusToJson(workerStatus: WorkerStatus) {
    return {
      nationality: workerStatus.nationality,
    };
  }

  fromJson(json: any): any {}

  toJson(worker: WorkerLite): any {
    return {
      firstName: worker.firstName,
      lastName: worker.lastName,
      gender: worker.gender,
      mobile: worker.mobile,
      email: worker.email,
      dateOfBirth: this.dateSerializer.toJson(worker.dateOfBirth),
      placeOfBirth: worker.placeOfBirth,
      languageCode: worker.languageCode,
      address: this.addressSerializer.toJson(worker.address),
      workerStatus: WorkerLiteSerializer.workerStatusToJson(worker.workerStatus),
      bankData: WorkerLiteSerializer.bankDataToJson(worker.bankData),
    };
  }
}
