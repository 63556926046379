export class Country {
  code: string;
  nameDE: string;
  nameEN: string;
  nameFR: string;
  nameNL: string;
  nameTranslated: string;

  public translateCountry(name: string): Country {
    this.nameTranslated = name;
    return this;
  }
}
