import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.css']
})
export class CreateAccountComponent implements OnInit {

  accountCreated = false;
  errorSignup = false;
  constructor() { }

  ngOnInit(): void {
  }

  setAccountCreated(value: any) {
    this.accountCreated = value;
  }

  setErrorSignup(value: any) {
    this.errorSignup = value;
  }

  getAppOnPlaystore() {
    if (/Android/i.test(navigator.userAgent)) {
      window.location.href = "https://play.google.com/store/apps/details?id=my.human.supports"
    }

    else {
      window.location.href = "https://play.google.com/store/apps/details?id=my.human.supports" 
    }
  }

  getAppOnAppstore() {
    window.location.href = "https://apps.apple.com/be/app/my-human-supports/id1515453358?l=fr" 
  }
}
