import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  success = false;
  error = false;
  constructor() { }

  ngOnInit(): void {
  }

  setSuccess(value: any) {
    this.success = value;
  }

  setError(value: any) {
    this.error = value;
  }

}
