import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {FacadeService} from '../../shared/service/facade/facade.service';
import {TranslateService} from '@ngx-translate/core';
import {WorkerUUID} from '../shared/model/worker-uuid.model';
import {User} from '../shared/model/user.model';

@Component({
  selector: 'app-set-password-form',
  templateUrl: './set-password-form.component.html',
  styleUrls: ['./set-password-form.component.css']
})
export class SetPasswordFormComponent implements OnInit {

  private readonly uuid: string;

  @Output() successEvent = new EventEmitter<boolean>();
  @Output() errorEvent = new EventEmitter<boolean>();

  form: UntypedFormGroup;
  isClicked = false;
  username: string;

  constructor(private formBuilder: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private facadeService: FacadeService,
              private translateService: TranslateService) {
    this.uuid = this.route.snapshot.queryParamMap.get('uuid');
    const language = this.route.snapshot.queryParamMap.get('language');
    if (language) {
      this.translateService.use(language.toUpperCase());
    }
  }

  ngOnInit() {
    this.buildForm();

    if (!this.uuid || this.uuid.trim().length < 1) {
      return this.router.navigate(['worker-account-error']);
    }

    this.getUsernameByCode();
  }

  private getUsernameByCode() {
    this.facadeService.getUserByUuid(this.uuid).subscribe({
      next: (data: WorkerUUID) => {
        if (!data.username || data.username === '') {
          return this.router.navigate(['worker-account-error']);
        }
        this.username = data.username;
        this.form.controls.username.setValue(this.username);
      },
      error: () => {
        return this.router.navigate(['worker-account-error']);
      }
    });
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      username: [{
        value: null,
        disabled: true
      }, Validators.required],
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required],
    });
  }

  onSubmit() {
    this.isClicked = true;
    const password = this.form.value.password.trim();
    const confirmPassword = this.form.value.confirmPassword.trim();
    const username = this.username;

    if (password.length < 8) {
      this.isClicked = false;
      return alert(this.translateService.instant('workerAccountPage.credentials.message.error.minCharacters'));
    }

    if (password !== confirmPassword) {
      this.isClicked = false;
      return alert(this.translateService.instant('workerAccountPage.credentials.message.error.passwordDifferent'));
    }

    const user: User = new User();
    user.password = password;
    user.username = username;

    this.facadeService.createUserAccount(this.uuid, user)
      .subscribe({
        next: () => {
          this.form.reset();
          this.successEvent.emit(true);
        },
        error: () => {
          this.errorEvent.emit(true);
        }
      });
  }

}
