import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WorkerLiteComponent} from './worker-lite/worker-lite.component';
import {WorkerAccountErrorComponent} from './worker-account/worker-account-error/worker-account-error.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {CreateAccountComponent} from './worker-account/create-account/create-account.component';
import {ResetPasswordComponent} from './worker-account/shared/component/reset-password/reset-password.component';

export enum ROUTES_URL {
  REGISTRATION = 'registration',
  WORKER_ACCOUNT = 'worker-account',
  RESET_PASSWORD = 'reset-password',
  WORKER_ACCOUNT_ERROR = 'worker-account-error'
}

const routes: Routes = [
  {path: '', redirectTo: ROUTES_URL.REGISTRATION, pathMatch: 'full'},


  {path: ROUTES_URL.REGISTRATION, component: WorkerLiteComponent},
  {path: ROUTES_URL.WORKER_ACCOUNT, component: CreateAccountComponent},
  {path: ROUTES_URL.RESET_PASSWORD, component: ResetPasswordComponent},
  {path: ROUTES_URL.WORKER_ACCOUNT_ERROR, component: WorkerAccountErrorComponent},

  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
