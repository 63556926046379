import {LanguageCode} from '../../shared/model/language-code.model';
import {Address} from '../../shared/model/address/address.model';


export class BankData {
  bic: string;
  iban: string;
}

export class WorkerStatus {
  nationality: string;
}

export enum Gender {
  MAN = 'MAN',
  WOMAN = 'WOMAN',
  X = 'X'
}

export class WorkerLite {
  firstName: string;
  lastName: string;
  gender: Gender;
  mobile: string;
  email: string;
  dateOfBirth: Date;
  placeOfBirth: string;
  languageCode: LanguageCode;
  address: Address = new Address();
  workerStatus: WorkerStatus = new WorkerStatus();
  bankData: BankData = new BankData();
}
