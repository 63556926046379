import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {WorkerLiteComponent} from './worker-lite/worker-lite.component';
import {ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './material.module';
import {FacadeService} from './shared/service/facade/facade.service';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TokenInterceptor} from './shared/service/token-interceptor.service';
import {MatSliderModule} from '@angular/material/slider';
import {WorkerAccountErrorComponent} from './worker-account/worker-account-error/worker-account-error.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {APP_BASE_HREF} from '@angular/common';
import {NgxIbanModule} from 'ngx-iban';
import {SpinnerComponent} from './shared/component/spinner/spinner.component';
import { SetPasswordFormComponent } from './worker-account/set-password-form/set-password-form.component';
import { CreateAccountComponent } from './worker-account/create-account/create-account.component';
import { ResetPasswordComponent } from './worker-account/shared/component/reset-password/reset-password.component';


@NgModule({
  declarations: [
    AppComponent,
    WorkerLiteComponent,
    WorkerAccountErrorComponent,
    NotFoundComponent,
    SpinnerComponent,
    SetPasswordFormComponent,
    CreateAccountComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    MatSliderModule,
    NgxIbanModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    FacadeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
