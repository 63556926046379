import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-worker-account-error',
  templateUrl: './worker-account-error.component.html',
  styleUrls: ['./worker-account-error.component.css']
})
export class WorkerAccountErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
