import {Serializer} from './serializer';
import {DatePipe} from '@angular/common';

export class DateSerializer implements Serializer {
  private datePipe: DatePipe = new DatePipe('en');

  fromJson(date: Date): Date {
    return (date) ? new Date(date) : null;
  }

  toJson(date: any) {
    return (date) ? this.datePipe.transform(date, 'yyyy-MM-dd') : null;
  }
}
