import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {WorkerLite} from '../model/worker-lite.model';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {WorkerLiteSerializer} from '../model/worker-lite.serializer';
import {Country} from '../../shared/model/country/country.model';
import {map} from 'rxjs/operators';
import {Bic} from '../../shared/model/bic.model';
import {CountrySerializer} from '../../shared/model/country/country.serializer';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class WorkerLiteService {
  private serviceURL = environment.user_service + 'pre-save/app';
  private workerLiteSerializer: WorkerLiteSerializer = new WorkerLiteSerializer();
  private countrySerializer: CountrySerializer = new CountrySerializer();

  constructor(private http: HttpClient,
              private translate: TranslateService) {
  }

  save(workerLite: WorkerLite): Observable<any> {
    return this.http.post<WorkerLite>(this.serviceURL + '/register', this.workerLiteSerializer.toJson(workerLite));
  }

  listCountries(): Observable<any> {
    return this.http.get<Country[]>(this.serviceURL + '/data/countries').pipe(
      map((data) => this.convertDataToCountries(data))
    );
  }

  getBicByIdentificationNumber(identificationNumber: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('code', String(identificationNumber));

    return this.http.get<Bic>(this.serviceURL + '/data/bic', {params});
  }

  private convertDataToCountries(data: any): Country[] {
    const countries: Country[] =  data.map(country => this.countrySerializer.fromJson(country));
    switch (this.translate.currentLang.toLowerCase()) {
      case 'fr': return countries.map(c => c.translateCountry(c.nameFR))
        .sort((a, b) => a.nameTranslated.localeCompare(b.nameTranslated));
      case 'nl': return countries.map(c => c.translateCountry(c.nameNL))
        .sort((a, b) => a.nameTranslated.localeCompare(b.nameTranslated));
      case 'de': return countries.map(c => c.translateCountry(c.nameDE))
        .sort((a, b) => a.nameTranslated.localeCompare(b.nameTranslated));
      default : return countries.map(c => c.translateCountry(c.nameEN))
        .sort((a, b) => a.nameTranslated.localeCompare(b.nameTranslated));
    }
  }
}
